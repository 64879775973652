import { FC } from "react";

import dynamic from "next/dynamic";

import CollapsibleBadgeList from "@components/BadgeList/components/CollapsibleBadgeList";
import StandardBadgeList from "@components/BadgeList/components/StandardBadgeList";
import { IBadgeProps } from "@components/BadgeList/types";
import { isEmpty } from "@utils/utils";

const HistoryBadgeList = dynamic(() => import("@components/BadgeList/components/HistoryBadgeList/HistoryBadgeList"), {
  ssr: false,
});

const BadgeList: FC<IBadgeProps> = (props) => {
  const { badges, type } = props;

  if (isEmpty(badges)) {
    return null;
  }

  switch (type) {
    case "standard":
      return <StandardBadgeList {...props} />;
    case "collapsible":
    case "licence":
    case "values":
      return <CollapsibleBadgeList {...props} />;
    case "history":
      return <HistoryBadgeList {...props} />;
    default:
      return null;
  }
};

export default BadgeList;
