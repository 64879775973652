import { FC } from "react";

import dynamic from "next/dynamic";

import { IBadgeProps } from "@components/BadgeList/types";

const HorizontalBadgeList = dynamic(
  () => import("@components/BadgeList/components/CollapsibleBadgeList/components/HorizontalBadgeList"),
  {
    ssr: false,
  }
);

const VerticalBadgeList = dynamic(
  () => import("@components/BadgeList/components/CollapsibleBadgeList/components/VerticalBadgeList"),
  {
    ssr: false,
  }
);

const CollapsibleBadgeList: FC<IBadgeProps> = (props) => {
  const { arrangement } = props;

  switch (arrangement) {
    case "horizontal":
      return <HorizontalBadgeList {...props} />;
    case "vertical":
      return <VerticalBadgeList {...props} />;
    default:
      return null;
  }
};

export default CollapsibleBadgeList;
