import { FC, useState } from "react";
import { useEffectOnce } from "react-use";

import { FlexboxProps, HStack } from "@chakra-ui/react";
import Link from "next/link";

import { IBadgeProps } from "@components/BadgeList/types";

import BadgeImage from "../../../BadgeImage/BadgeImage";

const StandardBadgeList: FC<IBadgeProps> = ({ desktopHorizontalAlign, mobileHorizontalAlign, badges }) => {
  const [isMounted, setIsMounted] = useState(false);
  const defaultAlign: FlexboxProps["justifyContent"] = "left";

  useEffectOnce(() => {
    (async () => {
      await Promise.all(
        badges.map((badge) => {
          if (badge.image) {
            return new Promise((resolve) => {
              const i = new Image();
              i.onload = () => {
                resolve("ok");
              };
              i.src = badge.image.path;
            });
          }

          return null;
        })
      );

      setIsMounted(true);
    })();
  });

  return (
    <HStack
      spacing={3}
      visibility={isMounted ? "inherit" : "hidden"}
      justifyContent={{ base: mobileHorizontalAlign || defaultAlign, md: desktopHorizontalAlign || defaultAlign }}
    >
      {badges.map((badge) => {
        if (badge.url) {
          return (
            <Link
              href={badge.url}
              key={badge.image.path}
              passHref
              target={badge.newTab ? "_blank" : "_self"}
              rel="nofollow"
            >
              <BadgeImage
                imageProps={{
                  maxW: "100%",
                }}
                badge={badge.image}
              />
            </Link>
          );
        }

        return <BadgeImage key={badge.image.path} badge={badge.image} />;
      })}
    </HStack>
  );
};

export default StandardBadgeList;
